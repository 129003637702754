import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <section className="flex items-center h-screen p-16">
      <div className="container flex flex-col items-center ">
        <div className="flex flex-col gap-6 text-center">
          <h1 className="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
            <span className="sr-only">Error</span>404
          </h1>
          <p className="text-2xl md:text-3xl dark:text-gray-300">
            {t('notFoundHeading')}
          </p>
          <Link
            className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
            to="/"
          >
            {t('notFoundBackHome')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;

import { AxiosInstance } from 'axios';
import { TagsDto } from './dtos/tags.dto';
import { PaginationDto } from './dtos/pagination.dto';

export interface TagsApi {
  fetchTags: (
    page: number | undefined,
    itemsPerPage: number | undefined
  ) => Promise<PaginationDto<TagsDto>>;
  addTag: (title: string, status: string) => Promise<TagsDto>;
  updateTag: (id: string, status: string) => Promise<TagsDto>;
  deleteTag: (id: string) => Promise<void>;
}

export const tagsApi = (api: AxiosInstance): TagsApi => ({
  fetchTags: async (
    page: number = 1,
    itemsPerPage: number = 10
  ): Promise<PaginationDto<TagsDto>> => {
    const response = await api.get<PaginationDto<TagsDto>>(
      '/v1/management/tags',
      {
        params: { page, limit: itemsPerPage },
      }
    );
    return response.data;
  },
  addTag: async (title: string, status: string) => {
    const response = await api.post<TagsDto>('/v1/management/tags', {
      title,
      status,
    });
    return response.data;
  },
  updateTag: async (id: string, status: string) => {
    const response = await api.put<TagsDto>(`/v1/management/tags/${id}`, {
      status,
    });
    return response.data;
  },
  deleteTag: async (id: string) => {
    await api.delete(`/v1/management/tags/${id}`);
  },
});

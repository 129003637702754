interface TableLoaderProps {
  numberOfColumns: number;
  numberOfRows?: number;
}

const TableLoader: React.FC<TableLoaderProps> = ({
  numberOfColumns,
  numberOfRows = 5,
}) => {
  return (
    <>
      {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: numberOfColumns }).map((_, colIndex) => (
            <td
              key={colIndex}
              className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11"
            >
              <div role="status" className="animate-pulse">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
              </div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableLoader;

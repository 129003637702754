import React, { useState } from 'react';

interface CreateTagButtonProps {
  onTagCreated: (tag: any) => void;
}

const CreateTagButton: React.FC<CreateTagButtonProps> = ({ onTagCreated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tagName, setTagName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tagStatus, setTagStatus] = useState('draft');
  const [errorMessage, setErrorMessage] = useState('');

  // Função para abrir e fechar o modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setTagName('');
    setTagStatus('draft');
    setErrorMessage('');
  };

  // Função para criar a tag
  const handleCreateTag = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch('/api/tags', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: tagName }),
      });

      if (!response.ok) {
        throw new Error('Failed to create tag');
      }

      const newTag = await response.json();
      onTagCreated(newTag); // Atualiza a lista de tags na página
      toggleModal(); // Fecha o modal após a criação bem-sucedida
    } catch (error) {
      setErrorMessage(`Failed to create the tag. Please try again. ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="fixed bottom-4 right-4 pb-2.5">
        <button
          className="inline-flex items-center justify-center gap-2.5 rounded-full bg-primary py-4 px-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-4"
          onClick={toggleModal}
        >
          <span>
            <svg
              className="h-8 w-8"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {' '}
              <path stroke="none" d="M0 0h24v24H0z" />{' '}
              <line x1="12" y1="5" x2="12" y2="19" />{' '}
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
          </span>
        </button>
      </div>

      {/* Modal para criar a tag */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold mb-4">Create New Tag</h2>

            <div className="mb-5.5">
              <label
                className="mb-3 block text-sm font-medium text-black dark:text-white"
                htmlFor="emailAddress"
              >
                Name
              </label>
              <div className="relative">
                <input
                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  type="text"
                  name="name"
                  id="name"
                  onChange={e => setTagName(e.target.value)}
                />
              </div>
            </div>

            <select
              value={tagStatus}
              onChange={e => setTagStatus(e.target.value)}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-blue-500 mb-4"
            >
              <option value="draft">Draft</option>
              <option value="in_review">In Review</option>
              <option value="published">Published</option>
              <option value="suspended">Suspended</option>
              <option value="archived">Archived</option>
            </select>

            {errorMessage && (
              <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
            )}

            <div className="mt-6 flex justify-end">
              <button
                onClick={toggleModal}
                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateTag}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-2 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8z"
                    ></path>
                  </svg>
                ) : null}
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateTagButton;

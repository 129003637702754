import React from 'react';
import { useAuth } from '../hooks/useAuth';
import Loader from './common/Loader';
import DefaultLayout from '../layouts/DefaultLayout';
import AuthLayout from '../layouts/AuthLayout';
import AppRoutes from '../routes';
import { useLocation } from 'react-router-dom';

interface LayoutSelectorProps {
  children: JSX.Element;
}

const LayoutSelector: React.FC<LayoutSelectorProps> = () => {
  const { user, loading: authLoading } = useAuth();
  const location = useLocation();

  if (authLoading) {
    return <Loader />;
  }

  const isAuthRoute = location.pathname.startsWith('/auth');

  if (isAuthRoute) {
    return (
      <AuthLayout>
        <AppRoutes />
      </AuthLayout>
    );
  }

  return user ? (
    <DefaultLayout>
      <AppRoutes />
    </DefaultLayout>
  ) : (
    <AuthLayout>
      <AppRoutes />
    </AuthLayout>
  );
};

export default LayoutSelector;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import Calendar from '../pages/Calendar';
import Chart from '../pages/Chart';
import ECommerce from '../pages/Dashboard/ECommerce';
import FormElements from '../pages/Form/FormElements';
import FormLayout from '../pages/Form/FormLayout';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Tables from '../pages/Tables';
import Alerts from '../pages/UiElements/Alerts';
import Buttons from '../pages/UiElements/Buttons';
import SignIn from '../pages/Authentication/SignIn';
import SignUp from '../pages/Authentication/SignUp';
import ProtectedRoute from '../components/ProtectedRoute';
import NotFoundPage from '../pages/NotFoundPage';
import Tags from '../pages/Tags';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="FlexGo | Admin Dashboard" />
                <ECommerce />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/calendar"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Calendar | FlexGo - Admin Dashboard" />
                <Calendar />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/profile"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Profile  | FlexGo - Admin Dashboard" />
                <Profile />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/forms/form-elements"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Form Elements | FlexGo - Admin Dashboard" />
                <FormElements />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/forms/form-layout"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Form Layout | FlexGo - Admin Dashboard" />
                <FormLayout />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/tables"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Tables | FlexGo - Admin Dashboard" />
                <Tables />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/settings"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Settings | FlexGo - Admin Dashboard" />
                <Settings />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/chart"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Basic Chart | FlexGo - Admin Dashboard" />
                <Chart />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ui/alerts"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Alerts | FlexGo - Admin Dashboard" />
                <Alerts />
              </>
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ui/buttons"
        element={
          <>
            <ProtectedRoute>
              <>
                <PageTitle title="Buttons | FlexGo - Admin Dashboard" />
                <Buttons />
              </>
            </ProtectedRoute>
          </>
        }
      />

      <Route
        path="/tags"
        element={
          <>
            <ProtectedRoute allowedRoles={['admin']}>
              <>
                <PageTitle title="Tags | FlexGo - Admin Dashboard" />
                <Tags />
              </>
            </ProtectedRoute>
          </>
        }
      />

      <Route
        path="/auth/signin"
        element={
          <>
            <PageTitle title="Signin | FlexGo - Admin Dashboard" />
            <SignIn />
          </>
        }
      />
      <Route
        path="/auth/signup"
        element={
          <>
            <PageTitle title="Signup | FlexGo - Admin Dashboard" />
            <SignUp />
          </>
        }
      />
      <Route
        path="*"
        element={
          <>
            <PageTitle title="Not found" />
            <NotFoundPage />
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import axios from 'axios';
import { auth } from '../firebase';
import { tagsApi } from './management';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(
  async config => {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.tags = tagsApi(api);

export const getUserProfile = async () => {
  try {
    const response = await api.get(`/v1/users/me`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export default api;

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { auth } from '../services/firebase';
import { onAuthStateChanged, User } from 'firebase/auth';
import { getUserProfile } from '../services/api';

interface AuthContextType {
  user: User | null;
  signed: boolean;
  loading: boolean;
  role: string | null;
  profile: Profile | null;
  fetchUserProfile: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  signed: false,
  role: null,
  profile: null,
  fetchUserProfile: () => {},
});

interface Profile {
  id: string;
  displaName: string;
  email?: string;
  role?: string;
  profileImage?: {
    full_url: string;
  };
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<string | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  const fetchUserProfile = useCallback(() => {
    if (user) {
      getUserProfile()
        .then(profile => {
          setProfile(profile);
        })
        .catch(error => {
          console.error('Failed to fetch user profile:', error);
        });
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async firebaseUser => {
      if (firebaseUser) {
        const tokenResult = await firebaseUser.getIdTokenResult();
        setRole(tokenResult.claims.role as string);
        setUser(firebaseUser);
        setLoading(false);
        fetchUserProfile();
      } else {
        setUser(null);
        setRole(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchUserProfile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        signed: Boolean(user),
        profile,
        loading,
        role,
        fetchUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import { TagsDto } from '../../services/api/management/dtos/tags.dto';
import api from '../../services/api/api';
import Pagination from '../../components/Tables/Pagination';
import { PaginationDto } from '../../services/api/management/dtos/pagination.dto';
import { renderTagStatus } from '../../utils/statusRender';
import TableLoader from '../../components/common/Loader/TableLoader';
import DeleteButton from '../../components/Tables/DeleteButton';
import Notification from '../../components/Notifications/Notification';
import CreateTagButton from './CreateTagButton';

const Tags = () => {
  const [tags, setTags] = useState<PaginationDto<TagsDto>>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState(0);

  const [alert, setAlert] = useState<{
    message: string;
    type?: 'success' | 'error' | 'info';
  }>({ message: '' });

  const fetchTags = async () => {
    const loadedTags = await api.tags.fetchTags(currentPage, itemsPerPage);
    setTags(loadedTags);
    setCurrentPage(loadedTags.meta.currentPage);
    setTotalItems(loadedTags.meta.totalItems);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchTags();
      } catch (err) {
        setAlert({ message: 'Failed to fetch tags', type: 'error' });

        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, itemsPerPage]);

  const handleDeleteTag = async (tagId: string) => {
    try {
      await api.tags.deleteTag(tagId);

      await fetchTags();
      setAlert({
        message: 'Tag deleted successfully!',
        type: 'success',
      });
    } catch (error) {
      setAlert({
        message: `Failed to delete the tag. Please try again. ${error}`,
        type: 'error',
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTagCreated = (_newTag: any) => {
    setAlert({ message: 'Tag created successfully!', type: 'success' });
  };

  return (
    <>
      <Breadcrumb pageName="Tags" />
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                    Title
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    Status
                  </th>
                  <th className="py-4 px-4 font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <TableLoader numberOfColumns={3} />
                ) : (
                  tags?.data.map((tag, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {tag.title}
                        </h5>
                        <p className="text-sm">${tag.id}</p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        {renderTagStatus(tag.status)}
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button className="hover:text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M2.75 19.25l1-4a1 1 0 0 1 .27-.45l12.5-12.5a2.121 2.121 0 0 1 3 3L7 19a1 1 0 0 1-.45.27l-4 1a.75.75 0 0 1-.8-.8z" />
                              <path d="M15.5 4.5l2 2-9.5 9.5-2-2 9.5-9.5z" />
                            </svg>
                          </button>
                          <DeleteButton
                            onConfirmDelete={() => handleDeleteTag(tag.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <Pagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>

        <CreateTagButton onTagCreated={handleTagCreated} />

        {alert.message != '' && (
          <Notification
            message={alert.message}
            type={alert.type!}
            duration={3000}
            onClose={() => setAlert({ message: '' })}
          />
        )}
      </div>
    </>
  );
};

export default Tags;

import React, { useEffect, useState } from 'react';

interface NotificationProps {
  message: string;
  title?: string;
  type?: 'error' | 'success' | 'info';
  duration?: number;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  message,
  title,
  type = 'error',
  duration = 5000,
  onClose,
}) => {
  const [progress, setProgress] = useState(100);

  const typeStyles = {
    error: {
      bg: 'bg-[#FEE2E2]', // Simula 15% de opacidade do vermelho #F87171
      textColor: 'text-[#B45454]',
      progressBg: 'bg-[#F87171] bg-opacity-80',
    },
    success: {
      bg: 'bg-[#D1FAE5]', // Simula 15% de opacidade do verde #34D399
      textColor: 'text-[#047857]',
      progressBg: 'bg-[#34D399] bg-opacity-80',
    },
    info: {
      bg: 'bg-[#DBEAFE]', // Simula 15% de opacidade do azul #3B82F6
      textColor: 'text-[#1D4ED8]',
      progressBg: 'bg-[#3B82F6] bg-opacity-80',
    },
  };

  const styles = typeStyles[type];

  useEffect(() => {
    const interval = duration / 100; // Progresso em incrementos de 1%
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev <= 0) {
          clearInterval(timer);
          onClose();
          return 0;
        }
        return prev - 1;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [duration, onClose]);

  return (
    <div
      className={`flex w-full max-w-md ${styles.bg} px-7 py-8 shadow-md dark:bg-[#1B1B24] dark:bg-opacity-30 md:p-9 fixed top-25 right-10 z-50`}
    >
      <div className="w-full">
        <h5 className={`mb-3 font-semibold ${styles.textColor}`}>
          {title || (type === 'error' ? 'An error occurred' : 'Notification')}
        </h5>
        <ul>
          <li className={`leading-relaxed ${styles.textColor}`}>
            {message || 'This is a notification message.'}
          </li>
        </ul>
      </div>
      <button
        onClick={onClose}
        className="absolute top-2 right-4 text-gray-500 text-lg font-bold"
      >
        &times;
      </button>
      <div className="absolute bottom-0 left-0 h-1 bg-opacity-30 bg-gray-400 w-full">
        <div
          style={{ width: `${progress}%` }}
          className={`h-full ${styles.progressBg} transition-all duration-100`}
        ></div>
      </div>
    </div>
  );
};

export default Notification;

import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const { user, signed, role } = useAuth();

  if (!signed) {
    return <Navigate to="/auth/signin" replace />;
  }

  if (user && role && allowedRoles && !allowedRoles.includes(role)) {
    return <NotFoundPage />;
  }

  return children;
};

export default ProtectedRoute;

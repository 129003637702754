import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { firebaseConfig } from '../configs/firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logout = async () => {
  await signOut(auth);
};

export { auth, logout };
